import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { GameContext } from "./context";
import MissionCard from "./MissionCard";
import Voting from "./Voting";
import chevronleft from "./chevron-left.svg";
import ipatMission from "./ipat_mission.json";
import ipatVariable from "./ipat_variable.json";

const Missions = () => {
  const [gameState, gameDispatch] = useContext(GameContext);
  const [showVoting, setShowVoting] = useState(false);

  useEffect(() => {
    const ipatMissions = ipatMission.ipat_mission.filter((mission) =>
      gameState.mission_ids.includes(mission.mission_id)
    );
    gameDispatch({
      type: "update-missions",
      payload: ipatMissions,
    });
  }, [gameState.mission_ids, gameDispatch]);

  useEffect(() => {
    gameDispatch({
      type: "update-variables",
      payload: ipatVariable.ipat_variable,
    });
  }, [gameDispatch]);

  return (
    <ContainerStyle>
      <MissionsStyle>
        {!showVoting && (
          <VotingTabStyle onClick={() => setShowVoting(true)}>
            <IconStyle src={chevronleft} alt="left arrow" />
            Beslutning
          </VotingTabStyle>
        )}
        <HeaderStyle>Dine missioner i runde {gameState.round}</HeaderStyle>
        {gameState.round === 1 && (
          <>
            <ParagraphStyle>
              Brug IPAT-værktøjet (link i øverste højre hjørne) for at finde ud
              af hvilke beslutninger, der løser hvilke missioner.
            </ParagraphStyle>
            <ParagraphStyle>
              Foretag valg i "Beslutning" menuen til højre.
            </ParagraphStyle>
          </>
        )}
        <CardCollectionStyle>
          {gameState.mission_ids.map((missionId, index) => (
            <MissionCard key={index} missionRank={index + 1} />
          ))}
        </CardCollectionStyle>
      </MissionsStyle>
      {showVoting ? (
        <Voting setShowVoting={(showVoting) => setShowVoting(showVoting)} />
      ) : (
        false
      )}
    </ContainerStyle>
  );
};

const ContainerStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const MissionsStyle = styled.div`
  position: relative;
  flex: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const VotingTabStyle = styled.div`
  position: absolute;
  padding: 13px 0;
  right: 0;
  top: 0;
  height: 45px;
  width: 160px;
  border-radius: 10px 0 0 10px;
  background: linear-gradient(105.79deg, #121636 6.37%, #5be2ff 129.39%);
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
`;

const IconStyle = styled.img`
  width: 15px;
  height: 15px;
  color: #fff;
  transform: translate(-10px, 3px);
`;

const HeaderStyle = styled.div`
  flex: 0;
  font-size: 3rem;
  line-height: 56px;
  text-align: center;
  padding-bottom: 30px;
`;

const ParagraphStyle = styled.div`
  font-size: 1.25rem;
  padding-bottom: 40px;
`;

const CardCollectionStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

export default Missions;
