import { useContext } from 'react'
import styled from 'styled-components'
import { GameContext } from './context'

const Rules = () => {
  const [gameState] = useContext(GameContext)

  return (
    <RulesStyle>
      <HeaderStyle>Spilleregler for IPAT-spillet</HeaderStyle>
      <ParagraphStyle>
        I hver runde får du tre missioner, som du skal forsøge at opfylde bedst muligt samlet set.
      </ParagraphStyle>
      <ParagraphStyle>
        Du løser missionerne ved at vælge de bedste valgmuligheder på fire
        dimensioner: befolkningsudvikling (Population), velstand (Affluence),
        udlednings-teknologi (Technology) og madvaner (Diet).
      </ParagraphStyle>
      <ParagraphStyle>
        IPAT-spillet er baseret på{' '}
        <LinkStyle href="https://ipat.tokni.com">IPAT-værktøjet</LinkStyle>, der viser
        konsekvenserne af dine valg. Der kan du også læse mere om modellen
        og de enkelte valgmuligheder. Det anbefales stærkt at bruge
        IPAT-værktøjet til at finde ud af, hvilke valgmuligheder er de bedste.
      </ParagraphStyle>
      <ParagraphStyle>
        Spillet er udviklet som del af{' '}
        <LinkStyle href="https://kaos.world">kaos.world</LinkStyle> projektet.
      </ParagraphStyle>
      {gameState.current_stage === 'game-not-started' && (
        <ParagraphStyle>
          Start spillet ved at trykke på ▶ knappen til venstre.
        </ParagraphStyle>
      )}
    </RulesStyle>
  )
}
const RulesStyle = styled.div`
  flex: 1;
  padding: 50px 5vw 0 10vw;
`

const HeaderStyle = styled.div`
  font-size: 3rem;
  line-height: 56px;
  text-align: left;
`

const ParagraphStyle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  font-size: 1rem;
  line-height: 29px;
  padding-top: 20px;
`

const LinkStyle = styled.a`
  color: white;
`

export default Rules
