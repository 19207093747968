import { useContext, useState } from 'react'
import styled from 'styled-components'
import { GameContext } from './context'
import chevronleft from './chevron-left.svg'

const votingAllOptions = {
  population: [
    'No change in population',
    'SSP1 Population',
    'SSP2 Population',
    'SSP3 Population',
    'SSP4 Population',
    'SSP5 Population',
    'Low fertility',
  ],
  affluence: [
    'No change in affluence',
    'Economic crisis',
    'Equality',
    'Catching up',
    'Shifting power',
    'SSP1 Affluence',
    'SSP2 Affluence',
    'SSP3 Affluence',
    'SSP4 Affluence',
    'SSP5 Affluence',
  ],
  technology: [
    'Business As Usual',
    'Strong Tech Development',
    'Radical Tech Development',
  ],
  diet: ['USA diet', 'IND diet', 'No change'],
}

const Voting = ({ setShowVoting }) => {
  const [gameState, gameDispatch] = useContext(GameContext)
  const [selectedOption, setSelectedOption] = useState('')
  const votingOptions =
    gameState.choice === 'population'
      ? votingAllOptions.population
      : gameState.choice === 'affluence'
      ? votingAllOptions.affluence
      : gameState.choice === 'technology'
      ? votingAllOptions.technology
      : gameState.choice === 'diet'
      ? votingAllOptions.diet
      : ['none']
  const headlineText =
    gameState.choice === 'population'
      ? 'Population'
      : gameState.choice === 'affluence'
      ? 'Velstand'
      : gameState.choice === 'technology'
      ? 'Teknologi'
      : gameState.choice === 'diet'
      ? 'Madvaner'
      : 'Ingen'
  return (
    <VotingStyle>
      <VotingTabStyle onClick={() => setShowVoting(false)}>
        Beslutning
        <IconStyle src={chevronleft} alt="left arrow" />
      </VotingTabStyle>
      <HeaderStyle>{headlineText}</HeaderStyle>
      <OptionListStyle>
        {votingOptions.map((votingOption, index) => (
          <OptionListItem
            key={index}
            onClick={() => {
              if (selectedOption === votingOption) {
                setSelectedOption('')
              } else setSelectedOption(votingOption)
            }}
            selected={selectedOption === votingOption}
          >
            {votingOption}
          </OptionListItem>
        ))}
      </OptionListStyle>
      {selectedOption !== '' ? (
        <ConfirmButtonStyle
          onClick={() => {
            gameDispatch({
              type: 'decision',
              payload: { selectedOption: selectedOption },
            })
            setSelectedOption('')
          }
        }
        >
          Bekræft valg
        </ConfirmButtonStyle>
      ) : (
        false
      )}
    </VotingStyle>
  )
}

const VotingStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #181b36;
`

const VotingTabStyle = styled.div`
  text-align: right;
  padding: 13px 20px;
  height: 45px;
  width: 400px;
  background: linear-gradient(105.79deg, #121636 6.37%, #5be2ff 129.39%);
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
`

const IconStyle = styled.img`
  width: 15px;
  height: 15px;
  color: #fff;
  transform: translate(10px, 3px) scaleX(-1);
`

const HeaderStyle = styled.div`
  font-size: 1.5rem;
  padding-top: 50px;
  text-align: center;
  padding-bottom: 5px;
`

const OptionListStyle = styled.ul`
  padding: 0;
`

const OptionListItem = styled.li`
  width: 350px;
  height: 50px;
  border-radius: 4px;
  border: solid white 1px;
  margin: 10px 0;
  padding: 15px 0;
  list-style-type: none;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#5BE2FF80' : '#242748')};
  &:hover {
    background-color: #1acd82;
  }
`

const ConfirmButtonStyle = styled.div`
  width: 350px;
  height: 50px;
  border-radius: 4px;
  margin: 50px 0 0 0;
  padding: 15px 0;
  list-style-type: none;
  cursor: pointer;
  background: linear-gradient(105.79deg, #121636 6.37%, #5be2ff 129.39%);
  &:hover {
    background: linear-gradient(106.9deg, #5be2ff -18.47%, #121636 131.07%);
  }
`

export default Voting
