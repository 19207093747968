import { useContext } from 'react'
import styled from 'styled-components'
import { GameContext } from './context'

const YourDecisions = () => {
  const [gameState ] = useContext(GameContext)

  return (
      <YourDecisionsStyle>
        <DecisionLabelStyle>
          {gameState.choices.diet !== '' ? 'Beslutninger' : null}
        </DecisionLabelStyle>
        <DecisionTextStyle>
          {gameState.choices.population !== ''
            ? `P: ${gameState.choices.population}`
            : null}
        </DecisionTextStyle>
        <DecisionTextStyle>
          {gameState.choices.affluence !== ''
            ? `A: ${gameState.choices.affluence}`
            : null}
        </DecisionTextStyle>
        <DecisionTextStyle>
          {gameState.choices.technology !== ''
            ? `T: ${gameState.choices.technology}`
            : null}
        </DecisionTextStyle>
        <DecisionTextStyle>
          {gameState.choices.diet !== ''
            ? `D: ${gameState.choices.diet}`
            : null}
        </DecisionTextStyle>
      </YourDecisionsStyle>
  )
}

const YourDecisionsStyle = styled.div`
  position: relative;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const DecisionLabelStyle = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  padding-bottom: 20px;
`

const DecisionTextStyle = styled.div`
  font-size: 1rem;
  font-weight: 300;
`

export default YourDecisions
