import { useContext } from 'react'
import styled from 'styled-components'
import triangle from './polygon.svg'
import { GameContext } from './context'
import YourDecisions from './YourDecisions'

const LeftMenu = () => {
  const [gameState, gameDispatch] = useContext(GameContext)

  return (
    <LeftMenuStyle>
      <UpperLeftMenuStyle>
        <PointsLabelStyle>Runde {gameState.round} / 3</PointsLabelStyle>
        <PlayButtonStyle>
          <CircleStyle />
          {gameState.current_stage === 'game-not-started' ? (
            <TriangleStyle
              src={triangle}
              alt="Play triangle"
              onClick={() => gameDispatch({ type: 'start-game' })}
            />
          ) : (
            <CountdownSpace>i gang</CountdownSpace>
          )}
        </PlayButtonStyle>
        <PointsAreaStyle>
          <PointsLabelStyle>Point</PointsLabelStyle>
          <PointsValueStyle>
            {gameState.points} / {gameState.max_points}
          </PointsValueStyle>
        </PointsAreaStyle>
        <YourDecisions />
      </UpperLeftMenuStyle>
      <LowerLeftMenuStyle
        onClick={() => gameDispatch({ type: 'toggle-rules' })}
      >
        {gameState.show_page === 'main'
          ? 'Spilleregler'
          : gameState.current_stage !== 'game-not-started'
          ? 'Tilbage til spillet'
          : ''}
      </LowerLeftMenuStyle>
    </LeftMenuStyle>
  )
}
const LeftMenuStyle = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 250px;
  background-color: #181b36;
  padding-top: 34px;
`

const UpperLeftMenuStyle = styled.div`
  flex: 1;
`

const PlayButtonStyle = styled.div`
  display: static;
  margin-top: 50px;
  cursor: pointer;
  &:hover {
    transform: scale(1.1, 1.1);
    transition-duration: 0.2s;
  }
`

const CircleStyle = styled.div`
  width: 162px;
  height: 162px;
  border-radius: 50%;
  border-style: solid;
  border-width: 8px;
  border-color: white;
  background-color: transparent;
  box-shadow: inset 0px 0px 3px 2px #fff, 0px 0px 3px 2px #fff;
  margin: auto;
  outline: none;
`

const TriangleStyle = styled.img`
  width: 80px;
  height: 80px;
  color: #fff;
  transform: translate(10px, -120px);
`
const CountdownSpace = styled.div`
  width: 80px;
  height: 80px;
  transform: translate(85px, -90px);
`

const PointsAreaStyle = styled.div`
  font-weight: 300;
`

const PointsLabelStyle = styled.div`
  font-size: 1.5rem;
`

const PointsValueStyle = styled.div`
  padding-top: 20px;
  font-size: 2.25rem;
`

const LowerLeftMenuStyle = styled.div`
  font-size: 1.25rem;
  flex: 0;
  margin-bottom: 40px;
  cursor: pointer;
`

export default LeftMenu
