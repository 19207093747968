import { useReducer } from 'react'
import styled from 'styled-components'
import Header from './Header'
import Body from './Body'
import { GameContext } from './context'
import { initialState, reducer} from './reducer'

const App = () => {
  const [gameState, gameDispatch] = useReducer(reducer, initialState)

  return (
    <GameContext.Provider value={[gameState, gameDispatch]}>
      <MainStyle>
        <Header />
        <Body />
      </MainStyle>
    </GameContext.Provider>
  )
}

const MainStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  width: 100vw;
  background-color: #0d1028;
`

export default App
