import { useContext } from 'react'
import styled from 'styled-components'
import { GameContext } from './context'

const YourResults = ({ roundResult }) => {
  const [gameState] = useContext(GameContext)

  return (
    <YourResultsStyle>
      <DecisionLabelStyle>
        {gameState.choices.diet !== ''
          ? 'Dine beslutninger gav følgende udfald i 2100:'
          : null}
      </DecisionLabelStyle>
      {roundResult.result.map((result, index) => {
        const variable = gameState.variables.filter(
          (variable) => variable.variable_name === result.variable_name
        )[0]
        if (variable.variable_unit === '%') {
          return (
            <DecisionTextStyle key={index}>
              {variable.variable_friendly_name}:{' '}
              <b>{(result.selected_value*100).toFixed(variable.variable_decimals)}
              {variable.variable_unit}</b>
            </DecisionTextStyle>
          )
        } else {
          return (
            <DecisionTextStyle key={index}>
              {variable.variable_friendly_name}:{' '}
              <b>{result.selected_value.toFixed(variable.variable_decimals)}{' '}
              {variable.variable_unit}</b>
            </DecisionTextStyle>
          )
        }
      })}
    </YourResultsStyle>
  )
}

const YourResultsStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const DecisionLabelStyle = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  padding-bottom: 20px;
  color: green;
`

const DecisionTextStyle = styled.div`
  font-size: 1.5rem;
  font-weight: 300;
  padding-bottom: 20px;
`

export default YourResults
