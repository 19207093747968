export const initialState = {
  show_page: 'rules', // 'rules', 'main'
  current_stage: 'game-not-started', // "game-not-started", "voting", "round-finished", "game-over"
  round: 0,
  choice: 'diet', // "none", "diet", "technology", "affluence", "population"
  mission_ids: [],
  missions: [],
  variables:[],
  choices: {
    population: '',
    affluence: '',
    technology: '',
    diet: '',
  },
  model_data: [],
  points: 0,
  max_points: 0,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'start-game':
      return {
        ...state,
        current_stage: 'voting',
        show_page: 'main',
        round: 1,
        points: 0,
        max_points: 0,
        choices: {
          population: '',
          affluence: '',
          technology: '',
          diet: '',
        },
        mission_ids: randomMissions(),
        missions: [],
      }
    case 'update-missions':
      return {
        ...state,
        missions: action.payload,
      }
    case 'update-variables':
      return {
        ...state,
        variables: action.payload,
      }
    case 'decision':
      const selectedOption = action.payload.selectedOption
      switch (state.choice) {
        case 'diet':
          return {
            ...state,
            choices: { ...state.choices, diet: selectedOption },
            choice: 'technology',
          }
        case 'technology':
          return {
            ...state,
            choices: { ...state.choices, technology: selectedOption },
            choice: 'affluence',
          }
        case 'affluence':
          return {
            ...state,
            choices: { ...state.choices, affluence: selectedOption },
            choice: 'population',
          }
        case 'population':
          return {
            ...state,
            choices: { ...state.choices, population: selectedOption },
            choice: 'diet',
            current_stage: 'round-finished',
          }
        default:
          return state
      }
    case 'add-points-for-round':
      return {
        ...state,
        points: state.points + action.payload.points,
        max_points: state.max_points + action.payload.max_points,
      }
    case 'update-round-results':
      return {
        ...state,
        round_results: action.payload,
      }
    case 'new-round':
      return {
        ...state,
        round: state.round + 1,
        choice: 'diet',
        current_stage: 'voting',
        choices: {
          population: '',
          affluence: '',
          technology: '',
          diet: '',
        },
        mission_ids: randomMissions(),
        missions: [],
      }
    case 'game-over':
      return {
        ...state,
        current_stage: 'game-over',
      }
    case 'toggle-rules':
      if (state.show_page === 'rules') {
        return { ...state, show_page: 'main' }
      } else {
        return { ...state, show_page: 'rules' }
      }
    default:
      return state
  }
}

const randomMissions = () => {
  const arr = []
  const arr2 = []
  const adjustment = Math.floor(Math.random() * 2)
  while (arr.length < 3) {
    const guess = Math.floor(Math.random() * 16) + 1
    if (arr2.indexOf(guess) === -1 && guess!==4 && guess!==12) {
      arr.push(guess + adjustment * 16)
      arr2.push(guess)
    }
  }
  return arr
}
