import { useContext } from 'react'
import styled from 'styled-components'
import { GameContext } from './context'

const GameOver = () => {
  const [gameState, gameDispatch] = useContext(GameContext)
  return (
    <GameOverStyle>
      <HeaderStyle>Spil slut</HeaderStyle>
      <ParagraphStyle>
        {gameState.points} point ud af {gameState.max_points} mulige. Svarer til{' '}
        {Math.round((gameState.points / gameState.max_points) * 100, 0)}%
      </ParagraphStyle>
      <ContinueButtonStyle
        onClick={() =>
          gameDispatch({
            type: 'start-game',
          })
        }
      >
        Prøv igen
      </ContinueButtonStyle>
    </GameOverStyle>
  )
}

const GameOverStyle = styled.div`
  position: relative;
  flex: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

const HeaderStyle = styled.div`
  font-size: 3rem;
  line-height: 56px;
  text-align: center;
  padding-bottom: 30px;
`

const ParagraphStyle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  line-height: 29px;
  padding-top: 20px;
`

const ContinueButtonStyle = styled.div`
  width: 350px;
  height: 50px;
  border-radius: 4px;
  margin: auto;
  padding: 15px 0;
  list-style-type: none;
  cursor: pointer;
  background: linear-gradient(105.79deg, #121636 6.37%, #5be2ff 129.39%);
  &:hover {
    background: linear-gradient(106.9deg, #5be2ff -18.47%, #121636 131.07%);
  }
`

export default GameOver
