import { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { GameContext } from "./context";
import MissionCard from "./MissionCard";
import YourResults from "./YourResults";
import ipatModelData from "./ipat_model_data.json";

const Summary = () => {
  const [gameState, gameDispatch] = useContext(GameContext);
  const [roundResult, setRoundResult] = useState({});

  useEffect(() => {
    // filter ipatModelData to only include population=$population, affluence=$affluence, technology=$technology, diet=$diet
    const ipatModelDataFiltered = ipatModelData.ipat_model_data.filter(
      (modelData) =>
        modelData.population === gameState.choices.population &&
        modelData.affluence === gameState.choices.affluence &&
        modelData.technology === gameState.choices.technology &&
        modelData.diet === gameState.choices.diet
    );
    const result = [];
    let points = 0,
      maxPoints = 0;
    gameState.missions.map((mission) => {
      const selectedValue = ipatModelDataFiltered[0][mission["variable_name"]];
      let missionPoints = 0;
      if (mission["mission_type"] === "less-than") {
        if (selectedValue <= mission["mission_threshold"]) {
          missionPoints = mission["mission_points"];
        }
      } else {
        if (selectedValue >= mission["mission_threshold"]) {
          missionPoints = mission["mission_points"];
        }
      }
      points += missionPoints;
      maxPoints += mission["mission_points"];
      const missionResult = {
        variable_name: mission["variable_name"],
        mission_type: mission["mission_type"],
        mission_threshold: mission["mission_threshold"],
        selected_value: selectedValue,
        points: missionPoints,
      };
      result.push(missionResult);
      return false;
    });
    setRoundResult({ result });
    gameDispatch({
      type: "update-round-results",
      payload: result,
    });
    gameDispatch({
      type: "add-points-for-round",
      payload: {
        points: points,
        max_points: maxPoints,
      },
    });
  }, [gameState.choices, gameDispatch, gameState.missions, setRoundResult]);

  return (
    <SummaryStyle>
      <HeaderStyle>Opsummering - runde {gameState.round}</HeaderStyle>
      {roundResult.result && (
        <>
          <YourResults roundResult={roundResult} />
          <CardCollectionStyle>
            {gameState.mission_ids.map((missionId, index) => {
              return (
                <MissionCard
                  key={index}
                  missionRank={index + 1}
                  points={roundResult.result[index].points}
                />
              );
            })}
          </CardCollectionStyle>
        </>
      )}
      <ContinueButtonStyle
        onClick={() => {
          if (gameState.round === 3) {
            gameDispatch({
              type: "game-over",
            });
          } else {
            gameDispatch({
              type: "new-round",
            });
          }
        }}
      >
        {gameState.round === 3 ? "Samlet resultat" : "Videre til næste runde"}
      </ContinueButtonStyle>
    </SummaryStyle>
  );
};

const SummaryStyle = styled.div`
  position: relative;
  flex: 1;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const HeaderStyle = styled.div`
  font-size: 3rem;
  line-height: 56px;
  text-align: center;
  padding-bottom: 30px;
`;

const CardCollectionStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const ContinueButtonStyle = styled.div`
  width: 350px;
  height: 50px;
  border-radius: 4px;
  margin: auto;
  padding: 15px 0;
  list-style-type: none;
  cursor: pointer;
  background: linear-gradient(105.79deg, #121636 6.37%, #5be2ff 129.39%);
  &:hover {
    background: linear-gradient(106.9deg, #5be2ff -18.47%, #121636 131.07%);
  }
`;

export default Summary;
