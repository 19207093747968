import { useContext } from 'react'
import styled from 'styled-components'
import { GameContext } from './context'
import LeftMenu from './LeftMenu'
import Rules from './Rules'
import Missions from './Missions'
import Summary from './Summary'
import GameOver from './GameOver'

const Body = () => {
  const [gameState] = useContext(GameContext)

  const renderSwitch = (param) => {
    if (gameState.show_page === 'rules') {
      return <Rules />
    }
    switch (param) {
      case 'game-not-started':
        return <Rules />
      case 'voting':
        return <Missions />
      case 'round-finished':
        return <Summary />
      case 'game-over':
        return <GameOver />
      default:
        return 'Ukendt side'
    }
  }

  return (
    <StyledBody>
      <LeftMenu />
      <RightBodyStyle>{renderSwitch(gameState.current_stage)}</RightBodyStyle>
    </StyledBody>
  )
}

const StyledBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100vw;
  color: #fff;
  text-align: center;
`

const RightBodyStyle = styled.div`
  flex: 1;
  background-color: #0D1028;
  color: #fff;
  text-align: center;
  display: flex;
`

export default Body
