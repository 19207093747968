import styled from 'styled-components'
import logo from './logo.png'
import externallinklight from './external-link-light.svg'

const Header = () => 
  <StyledHeader>
    <LeftHeaderStyle>
      <img src={logo} alt="logo" />
    </LeftHeaderStyle>
    <RightHeaderStyle>
    <OpenIpatdStyle>
        Åbn IPAT(D) værktøj
        <AnchorStyle href="https://ipat.tokni.com" target="_blank">
          <IconStyle src={externallinklight} alt="External link" />
        </AnchorStyle>
      </OpenIpatdStyle>
      </RightHeaderStyle>
  </StyledHeader>

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  z-index: 3000;
  height: 100px;
  min-height: 100px;
  width: 100vw;
  background-color: #242748;
`

const LeftHeaderStyle = styled.div`
  flex: 1;
  max-width: 250px;
  background-color: #242748;
  margin: auto 0;
`

const RightHeaderStyle = styled.div`
  flex: 1;
  background-color: #181b36;
  text-align: right;
`

const OpenIpatdStyle = styled.div`
  color: #fff;
  padding: 30px;
`

const AnchorStyle = styled.a`
  text-decoration: none;
`

const IconStyle = styled.img`
  width: 20px;
  height: 20px;
  color: #fff;
  transform: translate(10px, 3px);
`


export default Header
