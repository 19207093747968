import { useContext } from 'react'
import styled from 'styled-components'
import { GameContext } from './context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// population_number: users
import { faUsers } from '@fortawesome/free-solid-svg-icons'
// gdp_per_capita: hand-holding-usd
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'
// world_gdp: dollar-sign
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'
// required_earths: globe-africa
import { faGlobeAfrica } from '@fortawesome/free-solid-svg-icons'
// temp_increase: temperature-high
import { faTemperatureHigh } from '@fortawesome/free-solid-svg-icons'
// annual_ghg_emissions: cloud-upload-alt
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
// fossil_share_of_primary_production: gas-pump
import { faGasPump } from '@fortawesome/free-solid-svg-icons'
// technology_factor: car-battery
import { faCarBattery } from '@fortawesome/free-solid-svg-icons'

const MissionCard = ({ missionRank, points = null }) => {
  const [gameState] = useContext(GameContext)
  const icons = [
    faUsers,
    faHandHoldingUsd,
    faDollarSign,
    faGlobeAfrica,
    faTemperatureHigh,
    faCloudUploadAlt,
    faGasPump,
    faCarBattery,
  ]

  return (
    <MissionCardStyle
      missionRank={missionRank}
      height={points === null ? '400px' : '450px'}
    >
      {gameState.missions[missionRank - 1] && (
        <IconStyle
          icon={icons[(gameState.missions[missionRank - 1].mission_id - 1) % 8]}
        />
      )}
      <MissionHeaderStyle>Mission {missionRank}:</MissionHeaderStyle>
      {gameState.missions.length > 0 && (
        <ParagraphStyle>
          {gameState.missions[missionRank - 1].mission_text}
        </ParagraphStyle>
      )}
      <PointsHeaderStyle>Point</PointsHeaderStyle>
      {gameState.missions.length > 0 && points === null && (
        <PointsStyle>
          {gameState.missions[missionRank - 1].mission_points}
        </PointsStyle>
      )}
      {gameState.missions.length > 0 && points !== null && (
        <PointsStyle>{points}</PointsStyle>
      )}
      {points === 0 && (
        <ResultParagraphStyle color="red">
          Mistede point: {gameState.missions[missionRank - 1].mission_points}
        </ResultParagraphStyle>
      )}
    </MissionCardStyle>
  )
}

const MissionCardStyle = styled.div`
  flex: 1;
  height: ${(props) => props.height};
  width: 265px;
  max-width: 265px;
  min-width: 265px;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  ${(props) =>
    props.missionRank === 1
      ? 'background: linear-gradient(346.55deg, rgba(180, 105, 255, 0.75) -12.56%, rgba(24, 28, 63, 0.5) 82.62%); box-shadow: 0px 66px 3px -63px #B469FF;'
      : props.missionRank === 2
      ? 'background: linear-gradient(346.55deg, rgba(74, 255, 179, 0.75) -12.56%, rgba(24, 28, 63, 0.5) 82.62%); box-shadow: 0px 66px 3px -63px #4AFFB3;'
      : 'background: linear-gradient(346.55deg, rgba(255, 68, 236, 0.75) -12.56%, rgba(24, 28, 63, 0.5) 82.62%); box-shadow: 0px 66px 3px -63px #FF44EC;'}
`

const IconStyle = styled(FontAwesomeIcon)`
  margin-top: 20px;
  font-size: 4rem;
  text-align: center;
  filter: drop-shadow(0px 0px 5px #fff);
`

const MissionHeaderStyle = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 22px;
  padding: 30px 0 10px 0;
`

const ParagraphStyle = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 23px;
  height: 100px;
  max-height: 80px;
`

const ResultParagraphStyle = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 23px;
  color: ${(props) => props.color};
`

const PointsHeaderStyle = styled.div`
  font-size: 1.25rem;
  padding-top: 30px;
`

const PointsStyle = styled.div`
  font-size: 3.125rem;
  font-weight: 400;
  line-height: 60px;
  text-shadow: 0px 0px 5px #fff;
`

export default MissionCard
